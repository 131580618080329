<template>

  <div >
    <div class="home" style="display: flex;flex-direction: column;align-items: center;min-width: 1200px; ">
    <div id="top" class="topv">
      <small-topview v-if="showSmall && !topHidden"> </small-topview>
      <top-view  ref="topviews"  v-if="!showSmall && !topHidden" @changeLang="changeLang"
        @selectTable="selectTable" @menu="userMenuHandler"></top-view>
    </div>
   
    <img ondragstart="return false"  id="top"  style="min-width: 1200px;" class="ctitle" src="../assets/coreate/标题.png"
      alt="">
    <img ondragstart="return false" v-if="!lang"  style="width: 486px;" @click="jumptojoin()" class="upbtn" src="../assets/coreate/上传作品按钮.png" alt="">
    <img ondragstart="return false" v-if="lang"  style="width: 486px;" @click="jumptojoin()" class="upbtn" src="../assets/home1/上传作品按钮-e.png" alt="">
    <img ondragstart="return false" v-if="!lang" id="js"  class="platemargintop plate" src="../assets/coreate/大赛介绍.png" alt="">
    <img ondragstart="return false" v-if="lang" id="js"  class="platemargintop plate" src="../assets/home1/大赛介绍-e.png" alt="">
    <!-- <img ondragstart="return false"  class="platemargintop2 plate" src="../assets/coreate/征集类别.png" alt=""> -->
    <img ondragstart="return false"  class="platemargintop2 plate"  src="../assets/home/征集head.png" alt="">
    <div v-if="lang" class="zjplate" style="-moz-backface-visibility: hidden;" >
        <div class="zjplate-item" style="height: 360px; " v-for="(item,index) in ['01','02','03','04','05','06']" :key="index">
              <div class="zjplate-item-box">
                <img ondragstart="return false" style="width: 100%;height: 100%; " :src="require('../assets/home1/'+item+'-1备份@2x.png')" alt="">
              </div>
              <div class="zjplate-item-mask">
                <img ondragstart="return false" style="width: 100%;height: 100%; " :src="require('../assets/home1/'+item+'-2备份@2x.png')" alt="">
              </div>
        </div>
    </div>
    <div v-if="!lang" class="zjplate" style="-moz-backface-visibility: hidden;">
        <div class="zjplate-item" style="" v-for="(item,index) in ['01','02','03','04','05','06']" :key="index">
              <div class="zjplate-item-box">
                <img ondragstart="return false" style="width: 100%;height: 100%; " :src="require('../assets/home/'+item+'-1@2x.png')" alt="">
              </div>
              <div class="zjplate-item-mask">
                <img ondragstart="return false" style="width: 100%;height: 100%; " :src="require('../assets/home/'+item+'-2@2x.png')" alt="">
              </div>
        </div>
    </div>
    <img ondragstart="return false" v-if="!lang"  class="platemargintop2 plate" src="../assets/home1/奖项设置.png" alt="">
    <img ondragstart="return false" v-if="lang"  class="platemargintop2 plate" src="../assets/home1/奖项设置-e.png" alt="">
    <img ondragstart="return false" v-if="!lang" id="lc"  class="platemargintop2 plate" src="../assets/coreate/大赛流程.png" alt="">
    <img ondragstart="return false" v-if="lang" id="lc"  class="platemargintop2 plate" src="../assets/home1/大赛流程备份.png" alt="">
    <!-- <img ondragstart="return false" id="zr"  class="platemargintop2 plate" src="../assets/coreate/评审阵容.png" alt=""> -->
    <img ondragstart="return false" id="zr"  class="platemargintop2 plate"  src="../assets/home/评审.png" alt="">
    <div v-if="!lang" class="zjplate-scroll" id="list" >
      <div style="display: flex;width: fit-content;">  
        <div class="zjplate-scroll-item" >
            <img ondragstart="return false"  style="width: 100%;" src="../assets/home1/评审1.png" alt="">
        </div>
        <div class="zjplate-scroll-item" style="margin-left: 40px;" >
          <img ondragstart="return false"  style="width: 100%;" src="../assets/home1/评审2.png" alt="">
        </div>
      </div>
    </div>

    <div v-if="lang" class="zjplate-scroll"  id="list">
      <div style="display: flex;width: fit-content;">
        <div class="zjplate-scroll-item" >
            <img ondragstart="return false"  style="width: 100%;" src="../assets/home1/评审1-e.png" alt="">
        </div>
        <div class="zjplate-scroll-item" style="margin-left: 40px;" >
          <img ondragstart="return false"  style="width: 100%;" src="../assets/home1/评审2-e.png" alt="">
        </div>
      </div>
    </div>
    
    <img ondragstart="return false" v-if="!lang" id="xz"  class="platemargintop2 plate" src="../assets/home1/参赛须知.png" alt="">
    <img ondragstart="return false" v-if="lang" id="xz"  class="platemargintop2 plate" src="../assets/home1/参赛须知-e.png" alt="">
    <img ondragstart="return false" v-if="!lang" id="hb"  class="platemargintop2 plate" src="../assets/home1/合作伙伴.png" alt="">
    <img ondragstart="return false" v-if="lang" id="hb"  class="platemargintop2 plate" src="../assets/home1/合作伙伴-e.png" alt="">

    <div class="toupiao" @click="jumptoart()">
      <div class="toupiaotext">{{$t('homePage.yxcy') }}</div>
      <div class="toupiaobtn">{{$t('homePage.tp') }}</div>
    </div>
    <div class="canshai" @click="jumptojoin()">
      <div class="toupiaotext">{{$t('homePage.sczp') }}</div>
      <div class="toupiaobtn">{{$t('homePage.sc') }}</div>
    </div>

    <div class="bline">
      <div class="bline-c">北京华声志海文化传播有限公司@2022</div>
      <a class="bline-c" style="margin-left: 16px;" href="https://beian.miit.gov.cn/">京ICP备2022016391号-4</a>
    </div>

   
  
  </div>
  <el-backtop  :right="100" :bottom="100" style="background-color: rgba(0, 0, 0, 0.01);" >
       <img ondragstart="return false" style="width: 50px; background-color: rgba(0, 0, 0, 0.01);" src="../assets/home/top.png" alt="">
		</el-backtop>
  </div>
  
</template>

<script>
// @ is an alias to /src

import * as API_URL from '../api/url'
import topView from './components/homeTop.vue'
import smallTopview from './components/smallScreenTopview.vue'
import debounce from '@/libs/debounce'
export default {
  name: 'Home',
  components: {
    topView,
    smallTopview,
  },
  data() {
    return {
      showSmall: false,
      screenWidth: 0,
      lang: false,
      topHidden: false,
      bgimgArr: ['背景_01.jpg', '背景_02.jpg', '背景_03.jpg', '背景_04.jpg', '背景_05.jpg'],
      userData:null
    }
  },
  methods: {
    messageClick() {
      this.$message.error('点击');
    },

    jumptoart() {
      // this.$router.push('/art')
      if(this.userData){
        window.open('#/art')
      }else{
        window.open('https://open.weixin.qq.com/connect/qrconnect?appid=wxee731c0f9dc6ad53&redirect_uri=https://design2023.cspace.vip/&response_type=code&scope=snsapi_login')
      }
     
    },

    jumptojoin() {
      if(this.userData){
        window.open('#/join')
      }else{
        window.open('https://open.weixin.qq.com/connect/qrconnect?appid=wxee731c0f9dc6ad53&redirect_uri=https://design2023.cspace.vip/&response_type=code&scope=snsapi_login')
      }
      // this.$router.push('/join')
     
    },

    // 切换标签
    selectTable(e) {
      console.log(e)
      var anchor;
      if (e == 0) {
        anchor = document.getElementById('top')
      }
      if (e == 1) {
        anchor = document.getElementById('js')
      } else if (e == 2) {
        anchor = document.getElementById('lc')
      } else if (e == 3) {
        anchor = document.getElementById('zr')
      } else if (e == 4) {
        anchor = document.getElementById('xz')
      } else if (e == 5) {
        anchor = document.getElementById('hb')
      }

      anchor.scrollIntoView({ behavior: "smooth" });

    },

    initScroll() {
      // 获取要绑定事件的元素
      const tarDom = document.getElementById("list");
      let flag; // 鼠标按下
      let downX; // 鼠标点击的x下标
      let scrollLeft; // 当前元素滚动条的偏移量
      tarDom.addEventListener("mousedown", function (event) {
        flag = true;
        downX = event.clientX; // 获取到点击的x下标
        scrollLeft = tarDom.scrollLeft; // 获取当前元素滚动条的偏移量
      });
      tarDom.addEventListener("mousemove", function (event) {
        if (flag) {
          // 判断是否是鼠标按下滚动元素区域
          let moveX = event.clientX; // 获取移动的x轴
          let scrollX = moveX - downX; // 当前移动的x轴下标减去刚点击下去的x轴下标得到鼠标滑动距离
          tarDom.scrollLeft = scrollLeft - scrollX; // 鼠标按下的滚动条偏移量减去当前鼠标的滑动距离
        }
      });
      // 鼠标抬起停止拖动
      tarDom.addEventListener("mouseup", function () {
        flag = false;
      });
      // 鼠标离开元素停止拖动
      tarDom.addEventListener("mouseleave", function () {
        flag = false;
      });
    },


    // 切换语言
    changeLang() {
      let isZh = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh';
      if (isZh === 'zh') {
        localStorage.setItem('lang', 'en'); // en表示英文，zh表示中文，可根据自己喜好设定，尽量通俗易懂
        this.$i18n.locale = 'en'
        // this.lang = true
      } else {
        localStorage.setItem('lang', 'zh');
        this.$i18n.locale = 'zh'
        // this.lang = false
      }
      // console.log('lang',this.lang)

      window.location.reload();
    },


    viewScroll() {
      let current =
        document.documentElement.scrollTop || document.body.scrollTop;
        const _this = this
      // let vh = window.innerHeight;
      if (current > 120) {
        if (!this.topHidden) {
          this.topHidden = true
        }
      }

      if (current < 120) {
        if (this.topHidden) {
          _this.topHidden = false
          console.log(_this.$refs.topviews)

        }
      }
    },

    resetScrennwidth: debounce(function () {
      this.screenWidth = document.body.clientWidth
    }, 300),

    // 监听返回页面是否下滑
    notifScroll() {
      // 下滑至指定区域
      const index = this.$route.params.index
      if (index) {
        const _this = this
        this.$nextTick(() => {
          _this.selectTable(index)
          _this.$route.params.index = 0
        })
      }
    },

    // 获取屏幕尺寸 并监听
    getScreenWidthAndNotif() {
      //获取屏幕尺寸
      this.screenWidth = document.body.clientWidth
      window.onresize = () => {
        console.log('高度');
        //屏幕尺寸变化
        return (() => {
          this.resetScrennwidth()
        })()
      }
      window.addEventListener('scroll', this.viewScroll, true)
    },
    // 监听微信扫码登录 回传code
    ntfScan() {

      var url = window.location.href ;             //获取当前url
      var dz_url = url.split('#')[0];                //获取#/之前的字符串
     var cs = dz_url.split('?')[1];                //获取?之后的参数字符串
     var cs_arr = cs? cs.split('&'):[];                    //参数字符串分割为数组
     var cs2={};
     for(var i=0;i<cs_arr.length;i++){         //遍历数组，拿到json对象
      cs2[cs_arr[i].split('=')[0]] = cs_arr[i].split('=')[1]
    }
    const code  = cs2.code;      
    if(code){
      console.log('codeobj', code)
        this.testCode(code)
    }

      // const codeobj = this.$route.query
      // console.log('ntfscan',this.$route.query)
      // if (codeobj.code) {
        // console.log('codeobj', codeobj.code)
        // this.testCode(codeobj.code)

      // }
     

    },

    testCode(val) {

      API_URL.login({code:val}).then((res) => {
        console.log('res.data=',res.data)
              if (res.code == 0) { 
                  localStorage.setItem('userData',JSON.stringify(res.data))
                  localStorage.setItem('accessToken',JSON.stringify(res.data.jwtToken))
                  setTimeout(() => {
                     var url = window.location.href ;             //获取当前url
                    var dz_url = url.split('#')[0];                //获取#/之前的字符串
                   var cs = dz_url.split('?')[0];  
                   window.location.href = cs
                  }, 100);
              }
          });
    },

    userMenuHandler(e){
      console.log(e)
      if(e<6){
        this.$router.push(
       {
        name :'user',
        params : {
          index:e
        }
       }
      )
      }else{
        console.log('退出登录')
        localStorage.setItem('userData','')
        localStorage.setItem('accessToken','')
        window.location.reload();
      }
     
    },

    setNormalLang(){
      let isZh = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh';
      if (isZh === 'zh') {
        this.lang = false
      } else {
        this.lang = true
      }

      console.log('lang',this.lang)
    },
    

  },

  mounted() {

    this.userData = localStorage.getItem('userData')
    this.getScreenWidthAndNotif()
    this.notifScroll()
    this.ntfScan()
    this.setNormalLang()
    this.initScroll()
    // this.testCode('081JxgGa1uolvF0tTvGa17EUWJ1JxgGu')
    

  },

  watch: {
    screenWidth: function (n) {
      if (n <= 950) {
        if (!this.showSmall) {
          this.showSmall = true
        }
      } else {
        if (this.showSmall) {
          this.showSmall = false
        }
      }
    }
  }

}


  // mounted() {
  


  // }
</script>

<style lang="scss" scoped>
@import url('./css/home.css');

.home {
  background-color: black;
  box-sizing: border-box;
  overflow-x: hidden;
  height: fit-content;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: url('../assets/coreate/背景.jpg');
  background-size: cover;
}

.topv {
  // position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 2;
  box-sizing: border-box;
}

.toupiao {
  position: fixed;
  right: calc(100vw * (40 / 1920));
  top: calc(100vw * (200 / 1920));
  width: calc(100vw * (212 / 1920));
  height: calc(100vw * (252 / 1920));
  font-size: 20px;
  border-radius: 12px;
  background-image: url('../assets/home/侧边-投票.png');
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;


}
.toupiaotext{
  margin-top: calc(100vw * (100 / 1920));
  font-weight: bold;
  font-size: calc(100vw * (20 / 1920));
  color: #333;
  margin-right: calc(100vw * (12 / 1920));
  margin-right: calc(100vw * (12 / 1920));
}

.toupiaobtn{
  margin-top: calc(100vw * (17 / 1920));
  margin-right: calc(100vw * (12 / 1920));
  font-weight: bold;
  font-size: calc(100vw * (32 / 1920));
  width: calc(100vw * (170 / 1920));;
  height: calc(100vw * (54 / 1920));;
  color:#fff;
  background-image: url('../assets/home/按钮.png');
  background-size: cover;
  line-height: calc(100vw * (54 / 1920));;
}

.canshai {
  position: fixed;
  right: calc(100vw * (40 / 1920));
  top: calc(100vw * (500 / 1920));
  width: calc(100vw * (212 / 1920));
  height: calc(100vw * (252 / 1920));
  background-image: url('../assets/home/侧边-参赛.png');
  background-size: cover;
  font-size: 20px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}



.bgimgv {
  width: 100%;
  position: relative;
  top: 0px;
  left: 0px;

}

.bgimg {
  width: 100%;
}

.centerview {
  position: relative;
  top: 90px;
  left: 0px;
  width: 100%;
  // height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
}

#centerview::-webkit-scrollbar {
  display: none;
}

.ctitle {
  margin-top: calc(100vw * (220 / 1920));
  width: calc(100vw * (1191 / 1920));
}

.upbtn {
  margin-top: calc(100vw * (160 / 1920));
  width: calc(100vw * (486 / 1920));
}


.platemargintop {
  margin-top: calc(100vw * (216 / 1920));
  min-width: 1200px;
}

.platemargintop2 {
  margin-top: calc(100vw * (150 / 1920));
  min-width: 1200px;
}


.plate {
  width: calc(100vw * (1200/ 1920));
}

.bline {
  height: 93px;
  width: 100%;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

}

.bline-c {
  color: #fff;
  height: 30px;
  line-height: 30px;
  font-size: calc(100vw * (16 / 1920));
}


.bottom-up{
  height: 100%;
        width: 100%;
        background-color: #f2f5f6;
        box-shadow: 0 0 6px rgba(0,0,0, .12);
        text-align: center;
        line-height: 40px;
        color: #1989fa;
}



</style>
