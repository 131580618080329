// 统一请求路径前缀在libs/axios.js中修改
import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest
} from '@/libs/axios';


//  交由商家申诉
export const storeComplain = (params) => {
  return putRequest(`/order/complain/status`, params)
}


//  获取售后原因分页列表
export const getAfterSaleReasonPage = (params) => {
  return getRequest('/order/afterSaleReason/getByPage', params)
}

//  删除售后原因
export const delAfterSaleReason = (id) => {
  return deleteRequest(`/order/afterSaleReason/delByIds/${id}`)
}


// 登录code 换取信息

export const login = (params) => {
  return postRequest(`/wechatWeb/login`,params)
}


// 是否有草稿箱

export const hasDraft = () => {
  return postRequest(`/competition/work/hasDraft`)
}

// 项目分类
export const category = () => {
  return getRequest(`/competition/category`)
}

// 选择类目
export const chooseCategory = (params) => {
  return postRequest(`/competition/work/chooseCategory`,params)
}

// 上传作品 详细信息 /competition/work/upload
// export const chooseCategory = () => {
//   return postRequest(`competition/work/saveOther`)
// }

// 上传作品 其他信息
export const saveOther = (params) => {
  return postRequest(`/competition/work/saveOther`,params)
}


//确认提交 /competition/work/commitWork
export const commitWork = (params) => {
  return postRequest(`/competition/work/commitWork`,params)
}

// 获取oss直传信息
export const ossMessage = () => {
  return postRequest(`/competition/work/policy`)
}

// 获取作品列表
export const list = (params) => {
  return postRequest(`/competition/work/list`,params)
}


// 获取作品投票
export const vote = (params) => {
  return postRequest(`/competition/vote/vote`,params)
}


// 草稿箱列表
export const drafts = () => {
  return postRequest(`/competition/work/drafts`)
}

// 投票记录
export const record = (params) => {
  return postRequest(`/competition/vote/record`,params)
}

// 我的作品列表 
export const myrecord = (params) => {
  return postRequest(`/competition/work/myList`,params)
}

// 我的收藏列表 
export const collectList = (params) => {
  return postRequest(`/competition/collect/collectList`,params)
}

// 收藏列表 类目列表
export const collectListCategory = (params) => {
  return postRequest(`/competition/collect/collectListCategory`,params)
}

//  投票记录 类目列表
export const voteListCategory = (params) => {
  return postRequest(`/competition/vote/voteListCategory`,params)
}

// 我的作品 类目列表
export const workCollectListCategory = (params) => {
  return postRequest(`/competition/work/collectListCategory`,params)
}


// 作品详情
export const objectDetail = (params) => {
  return postRequest(`/competition/work/detail`,params)
}

// 添加收藏
export const addCollect = (params) => {
  return postRequest(`/competition/collect/addCollect`,params)
}

// 取消收藏
export const cancelCollect = (params) => {
  return postRequest(`/competition/collect/cancelCollect`,params)
}
// /competition/vote/voteNum
// 取消收藏
export const voteNum = () => {
  return postRequest(`/competition/vote/voteNum`)
}



// 删除图片相关接口
export const removeOther = (params) => {
  return postRequest(`/competition/work/removeOther`,params)
}

// 删除作品
export const removeWork = (params) => {
  return postRequest(`/competition/work/removeWork`,params)
}

// 获取用户信息
export const selectUserInfo = () => {
  return postRequest(`/wechatWeb/selectUserInfo`)
}


// 修改用户信息
export const updateUserInfo = (params) => {
  return postRequest(`wechatWeb/updateUserInfo`,params)
}